import Layout from '../components/Layout';
import { Link } from "gatsby";
import Page from '../components/Page';
// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import { useSiteMetadata } from '../hooks';

const SubscriberTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <Layout title={`Welcome to the club - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="Welcome to the club!">
        <p>Congratulations. You're part of the family now!</p>
        
        <p>You’re now subscribed and will get a heads up every time I release an article.</p>

        <Link className={""} to="/">
            See All Articles
        </Link>
      </Page>
    </Layout>
  );
};

export default SubscriberTemplate;
